<script>
import NProgress from "nprogress";
import Email from "../../components/auth/Email";
import Axios from "axios";

export default {
    name: "form-forgot-password",
    components: {
        Email
    },
    data() {
        return {
            userEmail: "",
            isCheckEmail: true
        };
    },
    methods: {
        sendForgotRequest() {
            NProgress.start();
            Axios({url: '/ir/auth/forgot/password', data: {email: this.userEmail}, method: 'POST'})
                .then(resp => {
                    this.isCheckEmail = !(resp && resp.data && resp.data.status === false);
                    //TODO remove after testing
                    if (!_.isEmpty(resp.data['_debug']) && !_.isEmpty(resp.data['_debug']['resetLink'])) {
                        window.location = resp.data['_debug']['resetLink'];
                    } else if (this.isCheckEmail) this.$router.push('/login');
                })
                .catch(err => {
                    console.log(err);
                }).finally(() => {
                NProgress.done();
            });
        }
    }
};
</script>

<template>
    <form method="POST" @submit.prevent="login">
        <h2 class="header-text">Forgot your Password?</h2>
        <p>Dont’ worry, just enter the email address your account is registered with and we will send
            you link to reset your password</p>
        <br/>
        <div class="form-group">
            <label>Email Address</label>
            <email v-model="userEmail"></email>
        </div>
        <div class="form-group">
            <button :disabled="!isCheckEmail || !userEmail"
                    type="button"
                    @click="sendForgotRequest"
                    class="btn input-btn">Send Password Reset Link
            </button>
        </div>
    </form>
</template>
<style scoped>
    button.btn:disabled{
        background-color:#cccccc !important;
    }
</style>