import { render, staticRenderFns } from "./forgotUsername.vue?vue&type=template&id=71ada9f9&scoped=true"
import script from "./forgotUsername.vue?vue&type=script&lang=js"
export * from "./forgotUsername.vue?vue&type=script&lang=js"
import style0 from "./forgotUsername.vue?vue&type=style&index=0&id=71ada9f9&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71ada9f9",
  null
  
)

export default component.exports