<template>
    <div>
        <span style="position: relative;">
            <input
                    type="email"
                    class="input-field"
                    :class="{'error': !isCheckEmail}"
                    placeholder="Enter your email address"
                    name="userEmail"
                    v-model="userEmail"
                    @input="checkEmail()"
            />
            <b-spinner v-if="isLoading" label="" variant="success" class="input-spinner"></b-spinner>
         </span>
        <div class="w-100 text-right">
            <span v-if="!isCheckEmail" class="error-text">
                <i class="bx bxs-error" style="color:#FF5E5E"></i>&nbsp;We could not find an account with that email address.
            </span>
        </div>
    </div>
</template>

<script>
    import Axios from "axios";
    import NProgress from "nprogress";
    import _ from "lodash";

    export default {
        name: "Email",
        data() {
            return {
                userEmail: this.value,
                isCheckEmail: true,
                isLoading: false,
            };
        },
        props: {
            value: {
                type: String,
                default: ''
            }
        },
        methods: {
            sendRequest() {
                NProgress.start();
                Axios({url: '/ir/auth/check/email', data: {email: this.userEmail}, method: 'POST'})
                    .then(resp => {
                        this.isCheckEmail = !(resp && resp.data && resp.data.status === false);
                        let email = (this.isCheckEmail) ? this.userEmail : '';
                        this.$emit('input', email);
                    })
                    .catch(err => {
                        console.log(err);
                    }).finally(() => {
                    NProgress.done();
                    this.isLoading = false;
                });
            },
            checkEmail() {
                if (this.userEmail == '') {
                    return;
                }
                this.isLoading = true;
                this.debouncedSendRequest();
            }
        },
        created: function () {
            this.debouncedSendRequest = _.debounce(this.sendRequest, 500);
        },
    };
</script>

<style scoped>
    .input-spinner {
        position: absolute;
        top: -2px;
        right: 10px;
        z-index: 10;
    }

    .text-success {
        color: #00B050 !important;
    }
</style>