<script>
/*eslint-disable */
import NProgress from "nprogress";
import Axios from "axios";

export default {
    name: "PasswordResetPage",
    data() {
        return {
            pass: "",
            retrypass: "",
            code: "",
            error: 0,
            errors: {
                'password1': '',
                'password2': ''
            },
            isLoading: false
        };
    },
    mounted() {
        this.code = this.$route.params.code;
    },
    methods: {
        sendRequest() {
            if (!this.isFormValid() || this.isLoading) return;
            this.isLoading = true;
            this.error = 0;
            NProgress.start();
            Axios({
                url: '/ir/auth/reset-password', data: {
                    code: this.code,
                    password1: this.pass,
                    password2: this.retrypass
                }, method: 'POST'
            })
                .then(resp => {
                    if (resp && resp.data) {
                        if (resp.data.status === true) this.$router.push('/login');
                        else this.error = resp.data.error;
                    }
                })
                .catch(err => {
                    console.log(err);
                }).finally(() => {
                NProgress.done();
                this.isLoading = false;
            });
        },
        isFormValid() {
            let text1 = '',
                text2 = '';
            if (_.isEmpty(this.pass)) text1 = 'Please enter new password.';
            if (_.isEmpty(this.retrypass)) text2 = 'Please retype new password.';

            if (!text1 && !text2) {
                if (!this.pass.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/)) text1 = 'Password is too simple.';
                else if (this.pass !== this.retrypass) text2 = 'Passwords do not match.';
            }

            this.errors.password1 = text1;
            this.errors.password2 = text2;
            return _.isEmpty(text1) && _.isEmpty(text2)
        }
    }
};
</script>

<template>
    <form method="POST" @submit.prevent="sendRequest">
        <h2 class="header-text">Password Reset</h2>
        <p>Reset your password by entering you new password details below</p>
        <br/>
        <div class="form-group">
            <label for="pass">New Password</label>
            <input
                    type="password"
                    class="input-field"
                    :class="{'error': errors.password1}"
                    placeholder="Enter New Password"
                    name="pass"
                    v-model="pass"
            />
            <div class="w-100 text-right" v-if="errors.password1">
                <span class="error-text">
                    <i class="bx bxs-error" style="color:#ff5e5e"></i>&nbsp;{{ errors.password1 }}
                </span>
            </div>
        </div>
        <div class="form-group">
            <label for="pass">Retype New Password</label>
            <input
                    type="password"
                    class="input-field"
                    :class="{'error': errors.password2}"
                    placeholder="Retype Password"
                    name="pass"
                    v-model="retrypass"
            />
            <div class="w-100 text-right" v-if="errors.password2">
                <span class="error-text">
                    <i class="bx bxs-error" style="color:#ff5e5e"></i>&nbsp;{{ errors.password2 }}
                </span>
            </div>
            <p class="small mt-2">*Must be more than 8 characters long,contain 1 capital letter,2
                numbers and no spaces.</p>
        </div>
        <div class="form-group">
            <button type="submit" class="btn input-btn">Reset My Password</button>
        </div>
    </form>
</template>