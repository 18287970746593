<script>
    import NProgress from "nprogress";
    import Axios from "axios";
    import Email from "../../components/auth/Email";

    export default {
        name: "form-forgot-username",
        components: {
            Email
        },
        data() {
            return {
                userEmail: "",
                isCheckEmail: true
            };
        },
        methods: {
            sendForgotRequest() {
                NProgress.start();
                Axios({
                    url: "/ir/auth/forgot/username",
                    data: {email: this.userEmail},
                    method: "POST"
                })
                    .then(resp => {
                        this.isCheckEmail = !(
                            resp &&
                            resp.data &&
                            resp.data.status === false
                        );
                        if (this.isCheckEmail) this.$router.push("/login");
                    })
                    .catch(err => {
                        console.log(err);
                    })
                    .finally(() => {
                        NProgress.done();
                    });
            }
        }
    };
</script>

<template>
    <form method="POST">
        <h2 class="header-text">Forgot your Username?</h2>
        <p>
            Don't worry, just enter the email address your
            account is registered with and we will send you your
            username
        </p>
        <br/>
        <div class="form-group">
            <label for="userEmail">Email Address</label>
            <email v-model="userEmail"></email>
        </div>

        <div class="form-group">
            <button
                    :disabled="!isCheckEmail || !userEmail"
                    type="button"
                    @click="sendForgotRequest"
                    class="btn input-btn"
            >
                Send Me My Username
            </button>
        </div>
    </form>
</template>
<style scoped>
    button.btn:disabled{
      background-color:#cccccc !important;
    }
</style>
